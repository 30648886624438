@import "@/assets/scss/style.scss";

.chat-action {
    cursor: pointer;
    &[aria-disabled="true"] {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.is-red {
    color: $ant-red;
}

