@import "@/assets/scss/style.scss";

.dropdown-button {
    font-size: 18px;
    color: $grey-9;
    cursor: pointer;
}

.ant-dropdown {
    width: 300px;

    .ant-dropdown-menu {
        min-width: 120px;

        li {
            padding: 9px 12px;
            @extend .u-b1;
        }
    }
}

.has-border {
    border: 1px solid $grey-1;
    border-radius: 4px;
    padding: 6px 6px;
}

