@import "@/assets/scss/style.scss";

.content {
    color: $grey-9;
    margin-bottom: 8px;

    &::v-deep {
        p {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
        }
    }
}

.plain-message-wrapper {
}


