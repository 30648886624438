@import "@/assets/scss/style.scss";

.attachment-list {
    &::v-deep {
        .ant-upload {
            display: none;
        }

        .ant-upload-list-item {
            margin-top: 0;
        }

        .ant-upload-list-item-name {
            color: $grey-7;
            @extend .u-b1;
        }
    }
}
