@import "@/assets/scss/style.scss";

.message-bundle {
    display: flex;
    flex-direction: column;
}
.message-wrapper {
    width: 35%;
    min-width: 200px;
    border: 1px solid $grey-4;
    border-radius: 4px;
    background-color: $white-two;
    padding: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 25px;
    margin-left: 45px;
    margin-right: 45px;

    &.is-right {
        align-self: flex-end;
    }

    &.is-next-step {
        padding-left: 36px;

        &:before {
            content: '';
            display: block;
            box-sizing: content-box;
            height: calc(100% - 32px);
            background-color: $blue-6;
            width: 4px;
            border-radius: 5px;
            position: absolute;
            top: 50%;
            left: 18px;
            transform: translateY(-50%);
        }
    }
}

