@import "@/assets/scss/style.scss";

.message-list-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    overflow-y: auto;
    //height: 75%;
    background-color: $grey-bg;
    flex: 1 1 100%;
}
