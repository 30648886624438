@import "@/assets/scss/style.scss";

.message-header-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: $grey-7;
}

